import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/interface/v1/analytics/types';
import { getCardsInstantInkRequestMoreInk, URLS } from './urls';

const activity = 'UnifiedAcctMgmt-v01';
export const screenPath = '/ReactMyAccountHome/';
export const screenName = 'Home';
const eventDetailVersion = '1.5.0';

export interface AnalyticsEventPropTypes {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlDetail?: string;
  version: string;
}

export interface AnalyticsEventWithControlName extends AnalyticsEventPropTypes {
  controlName: string;
}

export const publishEvent = (
  events: AnalyticsEventPropTypes[],
  options?: PublishCdmEventsOptions
): void => {
  const publishCdmEvents = (window as any).Shell?.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const cdmEvents = events.map((event) => ({
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  }));

  publishCdmEvents(cdmEvents, options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

export const HomeScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const ChangePasswordHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangePasswordLink',
  controlDetail: URLS.Cards.LoginAndSecurity.ChangePassword,
  version: eventDetailVersion
};

export const ChangePasswordOnPortalHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ChangePasswordOnPortalLink',
    controlDetail: URLS.Cards.LoginAndSecurity.ChangePassword + '/password',
    version: eventDetailVersion
  };

export const UpdatePersonalDetailsHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'UpdatePersonalDetailsLink',
    controlDetail: URLS.Cards.LoginAndSecurity.UpdatePersonalDetails,
    version: eventDetailVersion
  };

export const ViewOrderHistoryHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ViewOrderHistoryLink',
  controlDetail: URLS.Cards.YourPurchases.ViewOrderHistory,
  version: eventDetailVersion
};

export const StartReturnHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'StartReturnLink',
  controlDetail: URLS.Cards.YourPurchases.StartReturn,
  version: eventDetailVersion
};

export const PrinterShowsAccountIssueHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'PrinterShowsAccountIssueLink',
    controlDetail: URLS.Cards.OtherLinks.PrinterShowsAccountIssue,
    version: eventDetailVersion
  };

export const AddPrinterHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'AddPrinterLink',
  controlDetail: URLS.Cards.OtherLinks.AddPrinter,
  version: eventDetailVersion
};

export const GetProductSupportHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'GetProductSupportLink',
    controlDetail: URLS.Cards.OtherLinks.GetProductSupport,
    version: eventDetailVersion
  };

export const RegisterDeviceHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'RegisterDeviceLink',
  controlDetail: URLS.Cards.OtherLinks.RegisterDevice,
  version: eventDetailVersion
};

export const UpdateInstantInkPaymentMethodHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'UpdateInstantInkPaymentMethodLink',
    controlDetail: URLS.Cards.InstantInk.UpdatePaymentMethod,
    version: eventDetailVersion
  };

export const ChangeInstantInkPlanHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ChangeInstantInkPlanLink',
    controlDetail: URLS.Cards.InstantInk.ChangePauseOrCancelPlan,
    version: eventDetailVersion
  };

export const ViewInstantInkPrintUsageHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ViewInstantInkPrintUsageLink',
    controlDetail: URLS.Cards.InstantInk.ViewPrintUsage,
    version: eventDetailVersion
  };

export const RequestMoreInkHyperlinkClicked = (
  language: string = 'en',
  country: string = 'us'
): AnalyticsEventWithControlName => ({
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'RequestMoreInkLink',
  controlDetail: getCardsInstantInkRequestMoreInk(language, country),
  version: eventDetailVersion
});

export const UpdateHpAllInPaymentMethodHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'UpdateHpAllInPaymentMethodLink',
    controlDetail: URLS.Cards.HpAllIn.UpdatePaymentMethod,
    version: eventDetailVersion
  };

export const ChangeHpAllInPlanHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ChangeHpAllInPlanLink',
    controlDetail: URLS.Cards.HpAllIn.ChangeOrCancelPlan,
    version: eventDetailVersion
  };

export const ViewHpAllInPrintUsageHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ViewHpAllInPrintUsageLink',
    controlDetail: URLS.Cards.HpAllIn.ViewPrintUsage,
    version: eventDetailVersion
  };

export const ContactHpAllInProLiveSupportHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ContactHpAllInProLiveSupportLink',
    controlDetail: URLS.Cards.HpAllIn.ContactProLiveSupport,
    version: eventDetailVersion
  };

export const ShowInstantInkCardModuleDisplayed = (
  totalSubscriptions: number = 0,
  totalInstantInk: number = 0,
  totalHpAllIn: number = 0
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `totalSubscriptionsCt=${totalSubscriptions}&totalActiveInstanInkCt=${totalInstantInk}&totalActiveHpAllInCt=${totalHpAllIn}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath,
    screenName: `${screenName}/InstantInkCard`,
    version: eventDetailVersion
  };
};

export const ShowHpAllInCardModuleDisplayed = (
  totalSubscriptions: number = 0,
  totalInstantInk: number = 0,
  totalHpAllIn: number = 0
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `totalSubscriptionsCt=${totalSubscriptions}&totalActiveInstanInkCt=${totalInstantInk}&totalActiveHpAllInCt=${totalHpAllIn}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath,
    screenName: `${screenName}/HpAllInCard`,
    version: eventDetailVersion
  };
};

export const UserNameError = (errorInfo: string): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPath,
    screenName: `${screenName}/UserNameError`,
    version: eventDetailVersion
  };
};

export const SubscriptionsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPath,
    screenName: `${screenName}/SubscriptionsError`,
    version: eventDetailVersion
  };
};
